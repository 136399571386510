//
// Global Config
//

// Init global functions and mixins
@import "global/init";

// Layout Breakpoints(bootstrap responsive breakpoints)
// Define the minimum and maximum dimensions at which your layout will change, adapting to different screen sizes, for use in media queries.
$kt-media-breakpoints: (
	// Extra small screen / phone
	xs: 0,

	// Small screen / phone
	sm: 576px,

	// Medium screen / tablet
	md: 768px,

	// Large screen / desktop
	lg: 1024px,

	//1024px,
	// Extra large screen / wide desktop
	xl: 1399px
) !default;

// Global rounded border mode
$kt-rounded: true !default;

// Border Radius
$kt-border-radius: 4px !default;

// Core icon codes(lineawesome icons: https://icons8.com/line-awesome)
$kt-action-icons: (
	close: "\f191",
	down: "\f110",
	up: "\f113",
	left: "\f111",
	right: "\f112",
	plus: "\f2c2",
	minus: "\f28e"
) !default;

// Core bold icon codes(lineawesome icons: https://keenthemes.com/metronic/preview/default/components/icons/flaticon.html)
$kt-action-bold-icons: (
	down: "\f1a3",
	up: "\f1a5",
	left: "\f1a4",
	right: "\f19d",
	close: "\f1b2"
) !default;

// Elevate shadow
$kt-elevate-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05) !default;
$kt-elevate-shadow-2: 0px 0px 13px 0px rgba(82, 63, 105, 0.1) !default;

// Dropdown shadow
$kt-dropdown-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !default;

// Custom scrollbar color
$kt-scroll-color: darken(#ebedf2, 6%) !default;

// Transition
$kt-transition: all 0.3s !default;

// Modal Z-index
$kt-modal-zindex: 1050 !default;

// dropdown Z-index
$kt-dropdown-zindex: 95 !default;

// State Color
$kt-state-colors: (
	// Metronic states
	brand:
		(
			base: #ffffff,
			inverse: #ffffff
		),
	light: (
		base: #ffffff,
		inverse: #282a3c
	),
	dark: (
		base: #282a3c,
		inverse: #ffffff
	),

	// Bootstrap states
	primary:
		(
			base: #147dbf,
			inverse: #ffffff
		),
	success: (
		base: #0abb87,
		//1dc9b7,
		inverse: #ffffff
	),
	info: (
		base: #5578eb,
		inverse: #ffffff
	),
	warning: (
		base: #ffb822,
		inverse: #111111
	),
	danger: (
		base: #fd397a,
		inverse: #ffffff
	)
) !default;

// Base colors
$kt-base-colors: (
	label: (
		1: #a2a5b9,
		2: #74788d,
		3: #595d6e,
		4: #48465b
	),
	shape: (
		1: #f0f3ff,
		2: #e8ecfa,
		3: #93a2dd,
		4: #646c9a
	),
	grey: (
		1: #f7f8fa,
		//#f4f5f8
		2: #ebedf2,
		3: darken(#ebedf2, 3%),
		4: darken(#ebedf2, 6%)
	)
) !default;

// Social network colors(see: https://brandcolors.net/)
$kt-social-colors: (
	facebook: (
		base: #3b5998,
		inverse: #ffffff
	),
	google: (
		base: #dc4e41,
		inverse: #ffffff
	),
	twitter: (
		base: #4ab3f4,
		inverse: #ffffff
	),
	instagram: (
		base: #517fa4,
		inverse: #ffffff
	),
	youtube: (
		base: #b31217,
		inverse: #ffffff
	),
	linkedin: (
		base: #0077b5,
		inverse: #ffffff
	),
	skype: (
		base: #00aff0,
		inverse: #ffffff
	)
) !default;

// Root Font Settings
$kt-font-families: (
	regular: unquote("Poppins, Helvetica, sans-serif"),
	heading: unquote("Poppins, Helvetica, sans-serif")
) !default;

// Root Font Settings
$kt-font-size: (
	size: (
		desktop: 13px,
		tablet: 12px,
		mobile: 12px
	),
	weight: 300
) !default;

// General Link Settings
$kt-font-color: (
	text: #646c9a,
	link: (
		default: kt-state-color(brand),
		hover: darken(kt-state-color(brand), 6%)
	)
) !default;

// Portlet settings
$kt-portlet: (
	min-height: (
		default: (
			desktop: 60px,
			mobile: 50px
		),
		sm: (
			desktop: 50px,
			mobile: 40px
		),
		lg: (
			desktop: 80px,
			mobile: 60px
		),
		xl: (
			desktop: 100px,
			mobile: 80px
		)
	),
	space: (
		desktop: 25px,
		mobile: 15px
	),
	bottom-space: (
		desktop: 20px,
		mobile: 20px
	),
	border-color: kt-base-color(grey, 2),
	bg-color: #ffffff,
	shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05)
) !default;

// Page padding
$kt-page-padding: (
	desktop: 25px,
	mobile: 15px
) !default;

// Page container width
$kt-page-container-width: 1380px !default;

// Custom Scroll(Perfect Scrollbar) size
$kt-custom-scroll-size: 4px !default;
